<template>
    <h2>OSOM has stopped operations</h2>

    All funds are still available and you should claim any remaining funds.<br/>
    If you were unable to withdraw your assets prior to the termination of service please contact <b>support@osom.finance</b> with the following information:
    <ul>
        <ol>* First Name</ol>
        <ol>* Middle Name (if any)</ol>
        <ol>* Last Name</ol>
        <ol>* Date of Birth</ol>
        <ol>* Email address used to identify with OSOM</ol>
        <ol>* (if any, keep the one that applies) I would like my € balance to be converted to Bitcoin / Ethereum</ol>
        <ol>* Ethereum and/or Bitcoin address where you would like the funds to be sent.</ol>
        <ol>- Bitcoin Address:</ol>
        <ol>- Ethereum Address:</ol>
    </ul>

    Clicking on the following link will generate an email with the list of required items listed above. <a href="mailto:support@osom.finance"> Email Support </a>
    <br/><br/>
    As the bank account used for € payments has been closed, all withdrawals of remaining € balances will be honoured in the cryptocurrency of your choice at the exchange rate at the moment of withdrawal.
    <br/><br/>
    If you have never done cryptocurrency transfers in the past, please refer to this guide for setting up a wallet or an exchange account: <a href="https://bit.ly/withdrawosom" target="_blank">https://bit.ly/withdrawosom</a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
