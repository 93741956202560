<template>
    <div class="logo">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 40" class="styled__StyledLogo-sc-11dymts-1 lfMcnm logo__icon"><path fill-rule="evenodd" clip-rule="evenodd" d="M26.112 20.42c-.111 6.836-5.684 12.346-13.09 12.346C5.542 32.766 0 27.214 0 20.282l.002-.277C.112 13.17 5.685 7.66 13.092 7.66c7.48 0 13.021 5.552 13.021 12.484l-.001.276zm-19.218-.036c.08 3.41 2.586 6.347 6.197 6.347 3.706 0 6.165-2.966 6.165-6.45l-.002-.24c-.082-3.41-2.587-6.346-6.232-6.346-3.671 0-6.13 2.966-6.13 6.449l.002.24z" fill="currentColor"></path><path d="M39.745 32.766c-4.344 0-8.477-1.352-11.49-4.023l3.783-4.473c2.418 1.907 5.115 2.913 7.953 2.913 1.821 0 2.802-.624 2.802-1.665v-.069c0-1.006-.806-1.56-4.134-2.323l-.432-.1c-4.937-1.146-8.707-2.631-8.814-7.323l-.002-.276c0-4.508 3.608-7.767 9.493-7.767 4.17 0 7.427 1.11 10.09 3.225l-3.398 4.75c-2.242-1.56-4.695-2.392-6.867-2.392-1.646 0-2.452.693-2.452 1.56v.07c0 1.11.84 1.595 4.239 2.357l.492.11c5.27 1.194 8.55 2.986 8.649 7.252l.002.268c0 4.958-3.958 7.906-9.914 7.906zm60.854-4.681V12.682h5.31v2.107c.979-1.281 2.376-2.449 4.5-2.449 2.013 0 3.55.911 4.361 2.506 1.313-1.538 2.851-2.506 5.059-2.506 3.214 0 5.171 2.05 5.171 5.638v10.107h-5.311v-8.37c0-1.68-.811-2.563-2.068-2.563-1.258 0-2.181.883-2.181 2.562v8.371h-5.31v-8.37c0-1.68-.811-2.563-2.069-2.563-1.257 0-2.152.883-2.152 2.562v8.371h-5.31z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M94.608 20c0 11.046-9.008 20-20.12 20-11.112 0-20.12-8.954-20.12-20s9.008-20 20.12-20c11.112 0 20.12 8.954 20.12 20zm-33.092 0c0 7.12 5.807 12.893 12.97 12.893 7.164 0 12.97-5.773 12.97-12.893 0-7.12-5.806-12.893-12.97-12.893-7.163 0-12.97 5.772-12.97 12.893z" fill="currentColor"></path></svg>
    </div>
    <HelloWorld/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: left;
}

.logo {
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}
</style>
